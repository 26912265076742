<template>
  <div class="repayment-container">
    <div class="repayment-content">
      <div class="rem30 lh21">借款总额</div>
      <div class="rem56 lh40 mt6 f500">￥{{ myLoan.origPrcp }}</div>
      <div class="c-4D7BFE rem30 lh21 mt6" @click="checkDetail">查看还款记录</div>

      <div class="flex just-between mt20">
        <div>借款状态</div>
        <div>{{ myLoan.odInd }}</div>
      </div>
      <div class="flex just-between mt20">
        <div>借据编号</div>
        <div>{{ myLoan.loanNo }}</div>
      </div>
      <div class="flex just-between mt20">
        <div>借款期限</div>
        <div>
          <span>{{ myLoan.tnr }}</span> 个月
        </div>
      </div>
      <div class="flex just-between mt20">
        <div>自动还款账户</div>
        <div @click="changeCard">
          <span>{{ bankInfo.bankName }}({{ bankCard }})</span>
          <span class="c-4D7BFE">修改</span>
        </div>
      </div>

      <div class="flex just-between mt20">
        <div>借款人姓名</div>
        <div>{{ myLoan.name }}</div>
      </div>
      <div class="flex just-between mt20">
        <div>借款人身份证</div>
        <div>{{ myLoan.maskIdNo }}</div>
      </div>
      <!-- <div class="flex just-between mt20">
        <div>收款账户</div>
        <div>中国工商银行(4301)</div>
      </div> -->
      <div class="flex just-between mt20">
        <div>起止日期</div>
        <div>{{ myLoan.intStartDt }}至{{ myLoan.lastDueDt }}</div>
      </div>
      <div class="flex just-between mt20">
        <div>借款合同</div>
        <div class="c-4D7BFE" @click="checkOut">查看</div>
      </div>
    </div>

    <repayment-plan ref="repayment" :myLoan="myLoan"></repayment-plan>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon, Button, Popup } from 'vant'
Vue.use(Icon)
Vue.use(Button)
Vue.use(Popup)

import repaymentPlan from '@/components/repayment-plan.vue'
import { Decrypt } from '@/utils/crypto'
export default {
  components: {
    repaymentPlan,
  },
  data() {
    return {
      loanNo: '', // 我的借款id
      myLoan: {}, // 我的借款
      info: {},
      bankInfo: {},
      bankCardNo: '',
      bankCard: '',
    }
  },
  created() {
    this.myLoan = this.$store.state.repayment.loan
    this.bankInfo = this.$store.state.repayment.bankInfo
    if (JSON.stringify(this.bankInfo) != '{}' && this.bankInfo.bankCardNo) {
      this.bankCardNo = Decrypt(this.bankInfo.bankCardNo)
      this.bankCard = this.bankCardNo.substr(-4)
    }
  },
  methods: {
    checkDetail() {
      this.$refs.repayment.show = true
    },

    changeCard() {
      this.$router.push('/add-back-info')
    },

    // 查看借款合同
    checkOut() {
      this.$router.push({
        path: '/repayContract',
        query: { loanNo: this.myLoan.loanNo },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.repayment-container {
  padding: 0.3rem /* 15/50 */ 0.4rem /* 20/50 */;
  .repayment-btn {
    margin-top: 2.22rem /* 111/50 */;
    .btn {
      margin-top: 0.4rem /* 20/50 */;
    }
  }
}
.img16 {
  width: 0.3rem;
}
</style>
